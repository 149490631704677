import React, { useState, useEffect } from 'react';

const MediaPlayer = ({ postId }) => {
  const [publicIP, setPublicIP] = useState('');
  const [urls, setUrls] = useState({ wowza_url: '', wowza_poster: '', youtube_url: '' });

  const publicIPArr = [
    '103.81.79.10', '103.81.79.15', '103.81.79.35', '103.81.78.10', 
    '103.81.78.15', '182.19.76.165', '123.63.49.135', '172.27.18.1', 
    '172.27.18.182', '172.26.90.25'
  ];

  useEffect(() => {
    // Fetch the public IP address
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        setPublicIP(data.ip);

        // Fetch URLs from the custom API endpoint
        fetch(`https://test-arun-nathani-blog.pantheonsite.io/wp-json/custom/v1/video_urls/${postId}`)
          .then(response => response.json())
          .then(urlData => {
            setUrls(urlData);
          })
          .catch(error => {
            console.error('Error fetching URLs:', error);
          });
      });
  }, [postId]);

  const getYouTubeId = url => {
    const match = url.match(/[\\?\\&]v=([^\\?\\&]+)/);
    return match ? match[1] : '';
  };

  return (
    <div>
      {publicIPArr.includes(publicIP) && urls.wowza_url ? (
        <div id="mediaplayer_wrapper" class="mediaplayer-wrapper">
          <video 
            controls 
            playsInline 
            autoPlay 
            disablePictureInPicture 
            controlsList="nodownload" 
            style={{ position: 'relative', display: 'block', width: '100%', height: '100%', outline: 0 }}
            poster={urls.wowza_poster}
          >
            <source src={urls.wowza_url} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </div>
      ) : (
        urls.youtube_url && (
          <div className="desktop_iframe mediaplayer-wrapper">
            <div className="rel_iframe">
              <iframe 
                width="100%" 
                height="285" 
                src={`https://www.youtube.com/embed/${getYouTubeId(urls.youtube_url)}?autoplay=1`} 
                allow="autoplay"
              ></iframe>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default MediaPlayer;
